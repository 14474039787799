import React from "react";
import Configurator from "./components/Configurator";

function App() {
  return (
    <div className="App">
      <Configurator />
    </div>
  );
}

export default App;