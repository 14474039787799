import React, { useState, useEffect } from "react";
import Einfamilienhaus from "./Einfamilienhaus"; // Correctly import the Einfamilienhaus component
import Kleingewerbe from "./Kleingewerbe"; // Correctly import the Kleingewerbe component
import Unternehmen from "./Unternehmen"; // Correctly import the Unternehmen component
import "../styles/Configurator.css";
import emailjs from 'emailjs-com';

function Configurator() {
  const [isAnyButtonActive, setIsAnyButtonActive] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [building, setBuilding] = useState(1); // Default building
  const [globalStates, setGlobalStates] = useState({
    photovoltaic: false,
    heatpump: false,
    wallbox: false,
    energyManager: false,
    energyStorage: false,
    serviceEmployee: false,
  });

  const buildingNames = React.useMemo(
    () => ({
      1: "Einfamilienhaus",
      2: "Kleingewerbe",
      3: "Unternehmen",
    }),
    []
  );

  const [hoveredFeature, setHoveredFeature] = useState(null);  // Track the feature hovered
  // Information for each feature
  const featureInfo = {
    photovoltaic: "Photovoltaik wandelt Sonnenlicht in Elektrizität um.",
    heatpump: "Wärmepumpen heizen oder kühlen Gebäude mit der Umgebungstemperatur.",
    wallbox: "Eine Wallbox ist eine Ladestation für Elektrofahrzeuge.",
    energyManager: "Ein Energiemanager optimiert den Energieverbrauch von Gebäuden.",
    energyStorage: "Ein Batteriespeicher erlaubt die Nutzung von Photovoltaik-Strom in Zeiten mit kleinerer Produktion.",
    serviceEmployee: "Unser Service kümmert sich um Wartung und Reparaturen."
  };

  const handleButtonHover = (feature) => {
    setHoveredFeature(feature);  // Set the feature for which info should be displayed
  };

  const handleButtonLeave = () => {
    setHoveredFeature(null);  // Hide the info when the mouse leaves the button
  };

  // Define a map for user-friendly labels
  const labels = React.useMemo(() => ({
    photovoltaic: "Photovoltaik",
    heatpump: "Wärmepumpe",
    wallbox: "Wallbox",
    energyManager: "Energie Manager",
    energyStorage: "Energiespeicher",
    serviceEmployee: "Service Mitarbeiter",
  }), []);

  // Update the state when any control button is clicked
  const toggleFeature = (feature) => {
    setGlobalStates((prevState) => {
      const updatedState = { ...prevState, [feature]: !prevState[feature] };
      checkIfAnyButtonActive(updatedState); // Check after state update
  
      // Check if all control buttons are turned off and close the accordion if open
      if (!Object.values(updatedState).some((state) => state) && formVisible) {
        setFormVisible(false); // Close the accordion if no buttons are active
      }
  
      return updatedState;
    });
  };

  useEffect(() => {
    const buildingInput = document.querySelector("input[name='selectedBuilding']");
    if (buildingInput) {
      buildingInput.value = buildingNames[building];
    }
  }, [building, buildingNames]); // Update whenever `building` changes 
  
  const renderBuilding = () => {
    console.log("States for current building:", globalStates);
    switch (building) {
      case 1:
        return <Einfamilienhaus states={globalStates} />;
      case 2:
        return <Kleingewerbe states={globalStates} />;
      case 3:
        return <Unternehmen states={globalStates} />;
      default:
        return null;
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const form = e.target;

    // Disable the submit button after it's clicked
    const submitButton = form.querySelector('button[type="submit"]');

    // Prevent multiple submissions if the button is disabled
    if (submitButton.disabled) {
      return; // Exit early if the button is already disabled
    }

    submitButton.disabled = true;

    // Collect selected checkboxes as an array
    const selectedComponents = Array.from(form.querySelectorAll("input[name='existingComponents']:checked"))
        .map(input => input.value)
        .join(", ") || "None";

    // Check if the honeypot field is filled out (this means it's a bot)
    const honeypot = e.target.honeypot.value;

    if (honeypot) {
      // If honeypot is filled out, it's a bot submission
      console.log("Bot detected! Form submission rejected.");
      alert("Error: Bitte wenden Sie sich an den Webseitenbetreiber.");
      return; // Do not submit the form
    }

    // Handle optional fields (check if they exist before accessing their values)
    const streetName = form.streetName ? form.streetName.value : "";
    const zipcode = form.zipcode ? form.zipcode.value : "";
    const city = form.city ? form.city.value : "";
    const currentHeating = form.currentHeating ? form.currentHeating.value : "";  // Only exists if heatpump is selected
    const rentalInterest = form.rentalInterest ? form.rentalInterest.value : ""; // Only add if it's present
    const roofMaterial = form.roofMaterial ? form.roofMaterial.value : ""; // Only include if visible
    const serviceType = form.querySelector('input[name="serviceType"]:checked') ? form.querySelector('input[name="serviceType"]:checked').value : "";
    const serviceProblem = form.serviceProblem ? form.serviceProblem.value : ""; // Problem description
    

    const formData = {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        phone: form.phone.value,
        streetName,  // Handles missing field
        zipcode,      // Handles missing field
        city,         // Handles missing field
        rentalInterest,  // Handles missing field
        selectedBuilding: form.selectedBuilding.value,
        choices: form.choices.value,
        roofMaterial,   // Handles missing field
        existingComponents: selectedComponents, // Correctly collects multiple checkbox values
        currentHeating, // Handles missing field
        serviceType,   // Handles missing field
        serviceProblem,   // Handles missing field
    };


    // If service is selected and no other options are selected, send service-specific email
    if (globalStates.serviceEmployee && !globalStates.photovoltaic && !globalStates.heatpump && 
      !globalStates.wallbox && !globalStates.energyManager && !globalStates.energyStorage) {

        // Send service-specific email
        emailjs
          .send(
            "smtp_solera", // Your EmailJS service ID
            "template_kzu938n", // Your service-specific template ID
            formData, // Form data including service problem and service type
            "wo4iGjzubfcZf2mrv" // Your EmailJS public key
          )
          .then((result) => {
            setSuccessMessage("Die E-Mail wurde erfolgreich gesendet!"); // Set success message
            setTimeout(() => {
              setSuccessMessage(""); // Clear the success message
            }, 5000);

            // Apply fade-out effect after 5 seconds
            setTimeout(() => {
              const banner = document.querySelector('.success-banner');
              if (banner) {
                banner.classList.add('fade-out');
              }
            }, 3500); // Start fade-out after 2 seconds (allow message to be visible for a bit)
            
            form.reset();
            setFormVisible(false);
          })
          .catch((error) => {
            alert("Fehler. Bitte versuchen Sie es später erneut.");
          });

      } else {

        emailjs
          .send(
            "smtp_solera",
            "template_73jid8k",
            formData,
            "wo4iGjzubfcZf2mrv"
          )
          .then((result) => {
              console.log("Email send:", result.text);
          })
          .catch((error) => {
              console.error("Error: Sending failed:", error.text);
          });

          emailjs
          .send(
            "smtp_solera",
            "template_4svhkd6",
            formData,
            "wo4iGjzubfcZf2mrv"
          )
          .then((result) => {
              console.log("Email to recipient sent:", result.text);
              setSuccessMessage("Die E-Mail wurde erfolgreich gesendet!"); // Set success message
              setTimeout(() => {
                setSuccessMessage(""); // Clear the success message
              }, 5000);

              // Apply fade-out effect after 5 seconds
              setTimeout(() => {
                const banner = document.querySelector('.success-banner');
                if (banner) {
                  banner.classList.add('fade-out');
                }
              }, 3500); // Start fade-out after 2 seconds (allow message to be visible for a bit)
              
              form.reset();
              setFormVisible(false);
            })
          .catch((error) => {
              alert("Fehler. Bitte versuchen Sie es später erneut.");
          });
      };
  };

  // Function to check if any control button is active
  const checkIfAnyButtonActive = (newGlobalStates) => {
    const activeButton = Object.values(newGlobalStates).some((state) => state);
    setIsAnyButtonActive(activeButton);
  };

  // Accordion toggle function
  const toggleAccordion = () => {
    if (isAnyButtonActive) {
      setFormVisible((prevState) => !prevState);
    }
  };

  return (
    <div className="configurator-container">
      <div className="configurator-grid">
        {/* Left Column: Controls */}
        <div className="controls">
          <button
            onClick={() => toggleFeature("photovoltaic")}
            onMouseEnter={() => handleButtonHover("photovoltaic")}
            onMouseLeave={handleButtonLeave}
            className={globalStates.photovoltaic ? 'active' : ''}
          >
            Photovoltaik
          </button>
          <button
            onClick={() => toggleFeature("heatpump")}
            onMouseEnter={() => handleButtonHover("heatpump")}
            onMouseLeave={handleButtonLeave}
            className={globalStates.heatpump ? 'active' : ''}
          >
            Wärmepumpe
          </button>
          <button
            onClick={() => toggleFeature("energyStorage")}
            onMouseEnter={() => handleButtonHover("energyStorage")}
            onMouseLeave={handleButtonLeave}
            className={globalStates.energyStorage ? 'active' : ''}
          >
            Batteriespeicher
          </button>
          <button
            onClick={() => toggleFeature("energyManager")}
            onMouseEnter={() => handleButtonHover("energyManager")}
            onMouseLeave={handleButtonLeave}
            className={globalStates.energyManager ? 'active' : ''}
          >
            Energiemanager
          </button>
          <button
            onClick={() => toggleFeature("wallbox")}
            onMouseEnter={() => handleButtonHover("wallbox")}
            onMouseLeave={handleButtonLeave}
            className={globalStates.wallbox ? 'active' : ''}
          >
            Wallbox
          </button>
          <button
            onClick={() => toggleFeature("serviceEmployee")}
            onMouseEnter={() => handleButtonHover("serviceEmployee")}
            onMouseLeave={handleButtonLeave}
            className={globalStates.serviceEmployee ? 'active' : ''}
          >
            Service
          </button>
        </div>

        {/* Right Column: Building */}
        <div className="building-container">
          <div className="switcher">
            <button onClick={() => setBuilding(1)} className={building === 1 ? 'active' : ''}
  >Einfamilienhaus</button>
            <button onClick={() => setBuilding(2)} className={building === 2 ? 'active' : ''}
  >Mehrfamilienhaus / Kleingewerbe</button>
            <button onClick={() => setBuilding(3)} className={building === 3 ? 'active' : ''}
  >Unternehmen</button>
          </div>

           {/* Tooltip Information for Desktop View */}
            {hoveredFeature && (
              <div className="popup-info">
                <p>{featureInfo[hoveredFeature]}</p>
              </div>
            )}

          {renderBuilding()}
        </div>
      </div>

      {/* Accordion Toggle Button */}
      <button
        className={`accordion-button ${!isAnyButtonActive ? "disabled" : ""}`}
        onClick={toggleAccordion}
        disabled={!isAnyButtonActive} // Disable the button if no control buttons are active
      >
        {formVisible ? "Schließen" : "Anfrage stellen!"}
      </button>

      {/* Success Message Banner */}
      {successMessage && (
        <div className="success-banner">
          <p>{successMessage}</p>
        </div>
      )}

      {/* Accordion Section */}
        <div className={`accordion ${formVisible ? 'open' : ''}`}>
          <h3>Ihre Anfrage</h3>
              <form name="offerRequest" onSubmit={sendEmail}>
                <div className="form-container">
                  <div className="question">
                    {/* Question 1 */}
                    {globalStates.photovoltaic && (
                      <>
                      <label className="legend-label-select">
                        Welches Dachmaterial ist verbaut?
                        <select name="roofMaterial" required>
                          <option value="">Bitte wählen...</option>
                          <option value="Ziegel">Ziegel</option>
                          <option value="Biberschwanz">Biberschwanz</option>
                          <option value="Flachziegel">Flachziegel</option>
                          <option value="Trapezblech">Trapezblech</option>
                          <option value="Sonstiges">Sonstiges/weiß nicht</option>
                        </select>
                      </label>
                      </>
                    )}

                    {/* Question 2 */}
                    <legend>Ist schon etwas vorhanden?</legend>
                    <label className="checkbox-label">
                      <input type="checkbox" name="existingComponents" value="Photovoltaik" /> Photovoltaik
                    </label>
                    <label className="checkbox-label">
                      <input type="checkbox" name="existingComponents" value="Wärmepumpe" /> Wärmepumpe
                    </label>
                    <label className="checkbox-label">
                      <input type="checkbox" name="existingComponents" value="Wallbox" /> Wallbox
                    </label>
                    <label className="checkbox-label">
                      <input type="checkbox" name="existingComponents" value="Energie-Manager" /> Energiemanager
                    </label>
                    <label className="checkbox-label">
                      <input type="checkbox" name="existingComponents" value="Energiespeicher" /> Batteriespeicher
                    </label>

                    {/* Question 3 */}
                    {globalStates.heatpump && (
                      <label className="legend-label-select">
                        Was ist Ihre aktuelle Heizform?
                        <select name="currentHeating">
                          <option value="">Bitte wählen...</option>
                          <option value="Öl-Heizung">Öl-Heizung</option>
                          <option value="Gas-Heizung">Gas-Heizung</option>
                          <option value="Pellet-Heizung">Pellet-Heizung</option>
                          <option value="Sonstige">Sonstige</option>
                        </select>
                      </label>
                    )}

                    {/* Question 4 */}
                    {globalStates.photovoltaic && (
                      <>
                      <legend>Sind Sie interessiert an <a href="https://www.solera.de/leistungen/pv-all-inclusive">Photovoltaik All-inclusive</a>?</legend>
                      <label className="checkbox-label">
                        <input type="radio" name="rentalInterest" value="PV-Miete" required /> Ja
                      </label>
                      <label className="checkbox-label">
                        <input type="radio" name="rentalInterest" value="PV-Kauf" required /> Nein
                      </label>
                      </>
                    )}

                    {/* Service Problem Field (Visible when only Service Employee is selected) */}
                    {globalStates.serviceEmployee &&
                      !globalStates.photovoltaic &&
                      !globalStates.heatpump &&
                      !globalStates.wallbox &&
                      !globalStates.energyManager &&
                      !globalStates.energyStorage && (
                      <div className="service-problem">
                        <label className="legendService" htmlFor="serviceProblem">Bitte beschreiben Sie kurz das aktuelle Problem:</label>
                        <textarea
                          id="serviceProblem"
                          name="serviceProblem"
                          placeholder="Ihre Problembeschreibung ..."
                          rows="4"
                          cols="50"
                        />
                        
                        {/* Service Type Selection (Wärmepumpen-Service or Photovoltaik-Service) */}
                        <div className="service-type-selection">
                          <legend>Um welchen Service handelt es sich?</legend>
                          <label>
                            <input
                              type="checkbox"
                              name="serviceType"
                              value="Wärmepumpen-Service"
                            />
                            Wärmepumpen-Service
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name="serviceType"
                              value="Photovoltaik-Service"
                            />
                            Photovoltaik-Service
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <div className="personal-info">
                    {/* Address Inputs */}
                    <label>
                      Vorname
                      <input type="text" name="firstName" required />
                    </label>
                    <label>
                      Nachname
                      <input type="text" name="lastName" required />
                    </label>
                    <label>
                      E-Mail-Adresse
                      <input type="email" name="email" required />
                    </label>
                    <label>
                      Telefonnummer
                      <input type="tel" name="phone" />
                    </label>
                    
                    {/* Explanation for address information */}
                    <div className="address-explanation">
                      <span>
                        Wenn Sie sich dafür entscheiden und zusätzliche Informationen zu liefern, können wir ein konkreteres Angebot unterbreiten. 
                        Diese Daten helfen uns über Map-Dienste einen ersten Überblick über Ihr Gebäude und die umliegenden Faktoren zu erhalten.
                      </span>
                    </div>

                    {/* "Zusätzliche Informationen" Checkbox */}
                    <label className="additional-information">
                      <input
                        type="checkbox"
                        onChange={() => setShowAddressFields((prev) => !prev)} // Toggle address field visibility
                      />
                      Zusätzliche Informationen (Adresse)
                    </label>

                    {/* Conditional Rendering of Address Fields */}
                    {showAddressFields && (
                      <div className="address-fields">
                        <label>
                          Straße, Hausnummer
                          <input type="text" name="streetName" />
                        </label>
                        <label>
                          Postleitzahl
                          <input type="text" name="zipcode" />
                        </label>
                        <label>
                          Stadt
                          <input type="text" name="city" />
                        </label>
                      </div>
                    )}

                    {/* Privacy Policy */}
                    <label className="checkbox-privacy">
                      <input type="checkbox" name="privacyPolicy" required />Ich akzeptiere die <a href="https://www.solera.de/Datenschutz/">Datenschutzrichtlinien</a>
                    </label>
                    <div className="honeypot">
                      <label htmlFor="honeypot" className="visually-hidden">
                        Do not fill out this field
                      </label>
                      <input
                        type="text"
                        id="honeypot"
                        name="honeypot"
                        className="honeypot-input"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                {/* Hidden input for choices */}
                {/*<input type="hidden" name="selectedBuilding" value="" />*/}
                <input
                  type="hidden"
                  name="selectedBuilding"
                  value={buildingNames[building]} // Dynamically set the building name
                />
                <input
                  type="hidden"
                  name="choices"
                  value={
                    Object.entries(globalStates)
                      .filter(([key, value]) => value) // Filter only true values
                      .map(([key]) => labels[key]) // Map keys to their labels
                      .join(", ") || "No options selected"
                  }
                />     
                <button type="submit">Senden</button>
              </form>
        </div>
    </div>
  );
}

export default Configurator;